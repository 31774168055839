@import '~@fontsource/roboto/100.css';
@import '~@fontsource/roboto/300.css';
@import '~@fontsource/roboto/400.css';
@import '~@fontsource/roboto/500.css';
@import '~@fontsource/roboto/700.css';
@import '~@fontsource/roboto/900.css';

h1,
h2,
h3 {
  font-weight: normal;
}

h1 {
  font-size: 24px;
}

li[role='menuitem'] {
  text-transform: capitalize;
}
